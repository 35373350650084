.chart[data-v-e4ade198] {
  height: 450px;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}
.chart[data-v-e4ade198] iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.report-page[data-v-79eb795e] {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.sider[data-v-79eb795e] {
  width: 250px;
  padding: 0 25px;
  height: calc(100vh - 95px);
  border-right: 1px solid var(--border-color);
  overflow: auto;
}
.right-main[data-v-79eb795e] {
  flex: 1;
}
.title[data-v-79eb795e] {
  height: 75px;
  display: flex;
  align-items: center;
  line-height: 75px;
  justify-content: space-between;
}
.title h3[data-v-79eb795e] {
  margin: 0;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.title .btn-container[data-v-79eb795e] {
  display: flex;
}
.container[data-v-79eb795e] {
  max-width: var(--79eb795e-maxWidth);
  margin: 0 auto;
  background: #fff;
}
.editor-container[data-v-79eb795e] {
  height: var(--79eb795e-height);
  overflow: auto;
}
[data-v-79eb795e] .ProseMirror {
  outline: none;
  caret-color: theme('colors.blue.600');
  word-break: break-word;
}
[data-v-79eb795e] .ProseMirror > * + * {
  margin-top: 0.75em;
}
/* Placeholder (on every new line) */
[data-v-79eb795e] .ProseMirror p.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
[data-v-79eb795e] .ProseMirror h2.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
[data-v-79eb795e] .ProseMirror h3.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
[data-v-79eb795e] .ProseMirror h4.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
[data-v-79eb795e] .ProseMirror table.tableWrapper {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
[data-v-79eb795e] .ProseMirror table.tableWrapper td,[data-v-79eb795e] .ProseMirror table.tableWrapper th {
  min-width: 1em;
  border: 2px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
[data-v-79eb795e] .ProseMirror table.tableWrapper td > *,[data-v-79eb795e] .ProseMirror table.tableWrapper th > * {
  margin-bottom: 0;
}
[data-v-79eb795e] .ProseMirror table.tableWrapper th {
  font-weight: bold;
  text-align: left;
  background-color: #f1f3f5;
}
[data-v-79eb795e] .ProseMirror table.tableWrapper .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
[data-v-79eb795e] .ProseMirror table.tableWrapper .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}
[data-v-79eb795e] .ProseMirror table.tableWrapper p {
  margin: 0;
}
[data-v-79eb795e] .ProseMirror .tableWrapper table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
[data-v-79eb795e] .ProseMirror .tableWrapper table td,[data-v-79eb795e] .ProseMirror .tableWrapper table th {
  min-width: 1em;
  border: 2px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
[data-v-79eb795e] .ProseMirror .tableWrapper table td > *,[data-v-79eb795e] .ProseMirror .tableWrapper table th > * {
  margin-bottom: 0;
}
[data-v-79eb795e] .ProseMirror .tableWrapper table th {
  font-weight: bold;
  text-align: left;
  background-color: #f1f3f5;
}
[data-v-79eb795e] .ProseMirror .tableWrapper table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
[data-v-79eb795e] .ProseMirror .tableWrapper table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}
[data-v-79eb795e] .ProseMirror .tableWrapper table p {
  margin: 0;
}
[data-v-79eb795e] .tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}
[data-v-79eb795e] .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.percent[data-v-d7d7321b] {
  text-align: end;
}
.percent[data-v-d7d7321b]::before {
  pointer-events: none;
  content: '';
  position: absolute;
  block-size: 100%;
  inline-size: var(--d7d7321b-size);
  inset-block: 0;
  inset-inline-start: 0;
  background-color: #6666FF66;
}

.filter_container[data-v-85cddb6c] {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.filter_container .select[data-v-85cddb6c] {
  margin-right: 15px;
}
.filter_container .operator[data-v-85cddb6c] {
  margin-right: 15px;
  max-width: 25%;
}
.filter_container .value[data-v-85cddb6c] {
  margin-right: 15px;
}
.filter_container .close[data-v-85cddb6c] {
  max-width: 25%;
  cursor: pointer;
}

.draggable-item[data-v-5c6c387d] {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.draggable-item .content[data-v-5c6c387d] {
  flex: 1 1 auto;
}

.table-container[data-v-fd17e16a] {
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}
.title[data-v-fd17e16a] {
  padding: 4px 8px;
  text-align: center;
}

.header[data-v-73dc828a] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
}
.setting[data-v-73dc828a] {
  cursor: pointer;
}
.close[data-v-73dc828a] {
  cursor: pointer;
}
.option-container[data-v-73dc828a] {
  padding: 0 15px;
  max-height: 600px;
  overflow-y: scroll;
}

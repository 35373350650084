.flex-col[data-v-3e99e9e0] {
  flex-direction: column;
}
.button-container[data-v-3e99e9e0] {
  width: 10rem;
  font-size: 13px;
}
.button[data-v-3e99e9e0] {
  border-width: 0;
  align-items: center;
  height: 2rem;
}
.w-4[data-v-3e99e9e0] {
  width: 1rem;
}
.h4[data-v-3e99e9e0] {
  height: 1rem;
}
.mr-2[data-v-3e99e9e0] {
  margin-right: 0.5rem;
}


.text-container[data-v-4f2f8e9c] {
	word-break: break-all;
	white-space: break-spaces;
}
.placeholder[data-v-4f2f8e9c] {
	height: 200px;
	width: 100%;
	text-align: center;
}
